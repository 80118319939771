import React from 'react';
import "./App.css";

class App extends React.Component {
  render() {
    return (
      <div className="background">
        <div className="cardxx">
          {/* Your content goes here */}
        </div>
      </div>
    );
  }
}

export default App;
